import React from 'react';


function Logo() {
    
    return (
        <img height="75px" width="75px" src="https://i.ibb.co/s9kptds/Mediamodifier-Design-Template-1.png" alt="Mediamodifier-Design-Template-1" className="Logo"></img>
    );
}

export default Logo;